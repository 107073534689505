<template>
	<div id="main">
		<div id="nav">
			<van-row>
				<van-col span="8">
				</van-col>
				<van-col span="8">
					使用记录查询
				</van-col>
				<van-col span="8">
				</van-col>
			</van-row>
		</div>
		<div>
			<van-row>
				<van-col span="24">
					<van-form @submit="onSubmit">
						<div style="margin: 16px;">
							<van-button round block type="info" native-type="submit">解锁限制</van-button>
						</div>
					</van-form>
				</van-col>
			</van-row>
		</div>
		<div>
			<van-row>
				<van-col span="24">
					<van-notice-bar left-icon="info-o">只有三次解锁限制机会，超出将自动封禁账户</van-notice-bar>
				</van-col>
			</van-row>
		</div>
		<div v-show="showRentPrise">
			<van-row>
				<van-col span="24">
					<van-collapse v-model="activeNames">
						<van-collapse-item :title="index" v-for="(item,index) in items" :key="index" :name="index">
							<p v-html="item"></p>
						</van-collapse-item>
					</van-collapse>
				</van-col>
			</van-row>
		</div>
		<van-tabbar v-model="active" @change="onChange">
			<van-tabbar-item icon="search" >查询</van-tabbar-item>
		</van-tabbar>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeNames: ["1"],
				msg: '',
				showRentPrise: false,
				items: {},
				URL: '',
				active: 0
			};
		},
		created() {
			//匹配目标参数
			var hrefUrl = window.location.href;
			var index = hrefUrl.indexOf("id=");
			this.showRentPrise = false;
			this.msg = '';
			this.$axios.get("/codes", {
				params: {
					"id": hrefUrl.substr(index + 3, hrefUrl.length),
				}
			}).then(res => {
				console.log(res.data.msg);
				this.items = res.data.datas;
				this.msg = res.data.msg;
				this.$dialog({
					message: res.data.msg
				});
				this.data = res.data.datas;
				this.showRentPrise = true;
			});
		},
		methods: {
			onChange(index) {
				const routerArray = [
					"/",
					"/Services",
				];
				this.$router.push(routerArray[index])
			},
			onSubmit() {
				//匹配目标参数
				var hrefUrl = window.location.href;
				var index = hrefUrl.indexOf("id=");
				this.showRentPrise = false;
				this.msg = '';
				this.$axios.get("/unlock", {
					params: {
						"id": hrefUrl.substr(index + 3, hrefUrl.length),
					}
				}).then(res => {
					console.log(res.data.msg);
					this.$dialog({
						message: res.data.msg
					});
				});
			}
		},
	};
</script>

<style>
	.custom-title {
		margin-right: 4px;
		vertical-align: middle;
	}

	#home {
		text-align: center;
	}

	.search-icon {
		font-size: 16px;
		line-height: inherit;
	}
</style>
